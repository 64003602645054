import { useRef, useState } from "react";
import Text3D from "../../components/common/Text3D";
import emailjs from '@emailjs/browser';
import { GlowingButton } from "../../components/GlowingButton";
import "./LandingPageStyles.css";

export default function ContactSection(props: any) {

    const form = useRef(null);
    const contactRef = props.contactRef;
    const [loading, setLoading] = useState(false);
    const [sentEmailOk, setSentEmailOk] = useState(false);
    const shadowColors3 = ['#31aacc', /* ... more colors as needed */];

    const sendEmail = (e: any) => {
        e.preventDefault();

        setLoading(true);

        emailjs.sendForm('service_qjc4hlg', 'template_mf1wa9a', form.current as any, 'f8WFBmygkXOeRQvuo')
            .then((result) => {
                console.log(result.text);
                setSentEmailOk(true);
            }, (error) => {
                console.log(error.text);
                alert(error.text);
                setLoading(false);
            });
    };

    return (
        <div id="contact-section" ref={contactRef}>
            {
                !sentEmailOk ?
                    !loading ?
                        <>
                            <Text3D
                                text={props.title ?? "Book a Free Introductory Call"}
                                color="white"
                                pixelOffset={2}
                                shadowColors={shadowColors3}
                                fontSize={'50px'}
                            />
                            <p className='description'>Book a free 10 minute call to discuss how we can incorporate Clash of Code into your school or for any general inquiries</p>
                            <form ref={form} onSubmit={sendEmail}>
                                <input name="to_name" type="text" className="feedback-input" placeholder="Name / School Name" />
                                <input name="to_email" type="email" className="feedback-input" placeholder="Email" />
                                <textarea name="comment" className="feedback-input" placeholder="Comment"></textarea>
                                <GlowingButton text={"SUBMIT"} color={'white'} />
                            </form>
                        </> : <>
                            <p className='description'>Sending...</p>
                        </>
                    : <>

                        <Text3D
                            text="INVITE SENT!"
                            color="white"
                            pixelOffset={2}
                            shadowColors={shadowColors3}
                        />

                        <p className='description'>Check your email and book in a time using the Calendly link, see you soon!</p>

                    </>
            }
        </div>
    );
}