import { useEffect, useRef, useState } from "react";
import { GlowingButton } from "../../components/GlowingButton";
import { Header } from "../../components/Header";
import { Heading } from "../../components/Heading";
import MiniGlitchText from "../../components/MiniGlitch";
import Text3D from "../../components/common/Text3D";
import { CodeLayer } from "../LandingPage/CodeLayer";
import ContactSection from "../LandingPage/ContactSection";
import "./Programs.css";
import { getUrl } from 'aws-amplify/storage';

export default function ProgramsPage(props: any) {

    const contactRef = useRef();
    const shadowColors = ['#FF007A', '#7DF5FE', /* ... more colors as needed */];
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {

        window.scrollTo(0, 0);



    }, []);

    const downloadPdf = async () => {
        setDownloading(true);
        try {
            getUrl({
                key: "clash-of-code-curriculum.pdf",

                options: {
                    accessLevel: 'guest', // can be 'private', 'protected', or 'guest' but defaults to `guest`
                    validateObjectExistence: false,  // defaults to false
                    expiresIn: 20 // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
                },

            }).then(async (v) => {
                const response = await fetch(v.url.toString());
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', "Clash-Of-Code-Program-Information.pdf");
                document.body.appendChild(link);
                link.click();
                link?.parentNode?.removeChild(link);

                setDownloading(false);
            })

        } catch (error) {
            console.error('Error downloading the file:', error);
            setDownloading(false);
        }
    };


    return (
        <div className='backdrop' style={{
            background: 'transparent',
        }}>
            <CodeLayer />
            <div className='backblack' style={{ height: '450px' }}></div>
            <Header onContactClick={() => {
                (contactRef.current as any)?.scrollIntoView({ behavior: 'smooth' });
            }} heightLimit={60} />
            <div id="programs-heading-section">
                <Heading fontSize={'40px'}>SCHOOL PROGRAMS</Heading>
                <p className='description' style={{
                    maxWidth: '900px',
                    marginBottom: '30px'
                }}>
                    We run a captivating after-school program where students learn <span className="s3">Python</span> programming through interactive gaming and competition. Each week builds their skills, leading up to a thrilling <span>coding battle</span> where they'll apply what they've learned in a friendly contest of strategy and creativity.
                </p>
                {
                    downloading ? <p className="description">Downloading...</p> :
                        <GlowingButton text={"Download Program"} onClick={() => {
                            downloadPdf();
                        }} />
                }
            </div>
            <div id="programs-main">

                <div id="curriculums-section">
                    <div className="vertical-flex half">
                        <Text3D
                            text="CURRICULUM"
                            color="white"
                            pixelOffset={2}
                            shadowColors={shadowColors}
                        />
                        <p className="description">
                            The curriculum is designed around exploration and is always tied to something tangible they can explore within the game encouraging them to explore further and giving them intrinsic motivation to learn.</p>
                        <p className="description">The <span>6 week after school program</span> with a total of 8 classes will give students a comprehensive understanding of programming fundamentals, Python and a sparked interest in programming.</p>
                        <p className="description">

                            The curriculum also hits most major points in the <span className="s3">ATAR & IB Computer Science Curriculum</span> ensuring success in the academic endeavours.
                        </p>
                        <div style={{ height: '20px' }}></div>
                    </div>
                    <div className='half image-holder' >
                        <div className="curriculum-image" id="curriculum-a-image">

                        </div>
                    </div>
                </div>
                <div id="curriculums-section">

                    <div className='half image-holder' >
                        <div className="curriculum-image" id="curriculum-b-image">

                        </div>
                    </div>

                    <div className="vertical-flex half">
                        <Text3D
                            text="COMPETITION"
                            color="white"
                            pixelOffset={-2}
                            shadowColors={shadowColors}
                        />
                        <p className="description">
                            Healthy competition always brings out the best in students thats why during the 6 week course students will curate a set of soldiers that they can put to the test in an <span className="s3">organized in-school tournament</span></p>
                        <p className="description"><span>Clash of Code</span> implements realtime strategy & coding to keep students engaged and on their toes. </p>
                        <p className="description"><span className="s2">Prizes & awards</span> for students can be arranged through us!</p>

                    </div>
                </div>

                <div style={{ height: '50px' }}></div>
                <p className="description" style={{
                    textAlign: 'center',
                    maxWidth: '500px'
                }}>
                    Checkout the curriculum breakdown & learning outcomes to see how it can benefit your students!
                </p>
                <div style={{ height: '20px' }}></div>
                {
                    downloading ? <p className="description">Downloading...</p> :
                        <GlowingButton color={'#BDFF00'} text={"Download Program PDF"} onClick={() => {
                            downloadPdf();
                        }} />
                }
                <div style={{ height: '50px' }}></div>
                <div className='slanted-border'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2512" height="72" viewBox="0 0 2512 72" fill="none">
                        <path d="M-35 68L2732 4" stroke="white" stroke-width="7" stroke-dasharray="14 14" />
                    </svg>
                </div>
                <ContactSection title={"Begin Integration"} contactRef={contactRef} />
                <MiniGlitchText>Clash of Code</MiniGlitchText>
                <div className='footer'>
                    <p>ajays.workemail@gmail.com</p>
                    <p>+61 0402670252</p>
                </div>
            </div>
        </div>
    )
}