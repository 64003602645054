import React from 'react';

export default function Text3D({ text = 0, color = 'white', shadowColors = [], fontFamily = 'Alegreya Sans SC', fontSize = 72, fontWeight = 800, pixelOffset = 4, }: any) {


    // Generate multiple text-shadow CSS
    const textShadows = shadowColors.map((shadowColor: any, index: number) => {
        return `${(index + 1) * pixelOffset}px ${(index + 1) * pixelOffset}px 0px ${shadowColor}`;
    }).join(',');

    const style = {

        color: color,
        textShadow: textShadows,
        fontSize: fontSize,
        fontFamily: fontFamily,
        fontWeight: fontWeight,
        display: 'inline-block',
        // whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        width: 'auto'
    };

    return (
        <div className="text-3d" style={style}>
            {text}
        </div>
    );
};
