
import { Header } from '../../components/Header';
import { Heading } from '../../components/Heading';
import { SocialsBar } from '../../components/common/SocialsBar';
import Text3D from '../../components/common/Text3D';
import { CodeLayer } from './CodeLayer';
import './LandingPageStyles.css';
import '../../components/common/Neon.css';
import { useEffect, useRef, useState } from 'react';

import { GlowingButton } from '../../components/GlowingButton';
import emailjs from '@emailjs/browser';
import MiniGlitchText from '../../components/MiniGlitch';
import { useNavigate, useSearchParams } from 'react-router-dom';

function LandingPage() {
    const shadowColors = ['#FF007A', '#7DF5FE', /* ... more colors as needed */];
    const shadowColors2 = ['#FF007A', /* ... more colors as needed */];
    const shadowColors3 = ['#31aacc', /* ... more colors as needed */];

    const white_to_dark = ['#68C1C8', '#498C91', '#386E72', '#2B5558', '#1A4649'];
    const green_to_dark = ['#9DD400', '#8BBB00', '#739B00', '#5C7C00', '#405700'];
    const contactRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const form = useRef(null);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [sentEmailOk, setSentEmailOk] = useState(false);

    const sendEmail = (e: any) => {
        e.preventDefault();

        setLoading(true);

        emailjs.sendForm('service_qjc4hlg', 'template_mf1wa9a', form.current as any, 'f8WFBmygkXOeRQvuo')
            .then((result) => {
                console.log(result.text);
                setSentEmailOk(true);
            }, (error) => {
                console.log(error.text);
                alert(error.text);
                setLoading(false);
            });
    };

    useEffect(() => {
        // console.log(moveTo)
        if (searchParams.get('moveTo') === 'contact') {
            (contactRef.current as any)?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [searchParams]);

    return <div className='backdrop'>
        {/* <div id="main-heading-hero-picture"></div> */}
        <CodeLayer />
        {/* <div id='main-heading-text'>
            <span className='heading-blue'>Code</span>
            <span className='heading-white' > Your Way to <br /></span>
            <span className='heading-blue'>Victory</span>
        </div> */}

        <div id="landing-page-main" >
            <Header onContactClick={() => {
                (contactRef.current as any)?.scrollIntoView({ behavior: 'smooth' });
            }} />
            <div className='backblack'></div>

            <div id="threejs-section">
                <div id='low-res-back'></div>

                <Heading>Clash of Code</Heading>
                <p className='description'>Unleash your code to conquer the digital battleground in <span className='s3'>Clash of Code</span> — where strategy meets syntax in the ultimate programming showdown!</p>
                {/* <div id="cta-heading"></div> */}
                {/* Socials Bar  */}
                <SocialsBar />
                {/* <BattleButton>BATTLE</BattleButton> */}
                <GlowingButton text={"Get In Touch"} onClick={() => {
                    (contactRef.current as any)?.scrollIntoView({ behavior: 'smooth' });
                }} />

            </div>
            <div id="about-section">
                <div id="about-main-pic">
                    <div id="about-pic" />
                </div>
                <div id="about-text">
                    <span style={{ marginRight: '20px' }}>
                        <Text3D
                            text="GAME BASED"
                            color="white" // Front color of the text.,
                            fontSize={'3em'}
                        />
                    </span>
                    <span>
                        <Text3D
                            text="LEARNING"
                            color="white"
                            pixelOffset={-2}
                            shadowColors={shadowColors}
                        />
                    </span>

                    <p className='description'>
                        Welcome to <span>Clash of Code</span>, the innovative programming game where learning meets competition!
                    </p>
                    <p className='description'>Our platform offers students the unique opportunity to dive into the world of coding by creating and controlling their own units in a dynamic, digital battleground, it gives them a sandbox environment to push their skills to the limit and to learn while playing.</p>
                    <p className='description'>Through <span>Clash of Code</span>, students harness the power of Python programming to strategize, and compete against their peers in an engaging battleground.</p>
                </div>
            </div>
            <div id="features-section">
                {/* <h1 className='neonText'>F E A T U R E S</h1> */}
                <Text3D
                    text="FEATURES"
                    color="#D3FF55"
                    pixelOffset={-3}
                    shadowColors={green_to_dark}
                />
                <div id="features-grid">
                    <div className="grid-item">
                        <h1 className='prevent-select'>BUILD AN ARMY</h1>
                        <p className='description prevent-select'>Build a <span>persistent deck</span> of units that you can grow and improve as you learn more and more creating your own unique play style and combat strategy </p>
                    </div>
                    <div className="grid-item">
                        <h1 className='prevent-select'>REALTIME STRATEGY</h1>
                        <p className='description prevent-select'>Connect Python variables directly to a custom <span>control panel</span> to tweak strategy in realtime and use a <span>realtime terminal</span> to execute commands and perform emergency maneuvers</p>
                    </div>
                    <div className="grid-item">
                        <h1 className='prevent-select'>VSCODE INTEGRATION</h1>
                        <p className='description prevent-select'>
                            Direct realtime connection with <span>VSCode</span> to provide a industry standard coding and debugging experience with intellisense & logging!
                        </p>
                    </div>
                    <div className="grid-item">
                        <h1 className='prevent-select'>COMPETITIVE MULTIPLAYER</h1>
                        <p className='description prevent-select'>
                            Students can compete using their decks in fast paced <span>multiplayer combat</span> providing an exciting and engaging sense of progression
                        </p>
                    </div>
                </div>
            </div>
            {/* <div className='slanted-border'>
                <svg xmlns="http://www.w3.org/2000/svg" width="2512" height="26" viewBox="0 0 2512 26" fill="none">
                    <path d="M-35.3411 4.04443L2732.34 21.9557" stroke="white" stroke-width="7" stroke-dasharray="14 14" />
                </svg>
            </div> */}
            <div id="learn-more-section">
                <Text3D
                    text="LEARN MORE"
                    color="#D2FCFF"
                    pixelOffset={3}
                    shadowColors={white_to_dark}
                />
                <div style={{ height: '50px' }}></div>
                <div className='half-flex' id="half-flex-2">
                    <div id="tutorial-image-2" className='image-div'>
                        {/* <video id="combat-strategy-video" src="./seq.mp4" autoPlay={true} loop={true} /> */}
                    </div>

                    <div >
                        {/* <Text3D
                            text="School Programs"
                            color="white"
                            fontSize='3em'
                            pixelOffset={-2}
                            shadowColors={shadowColors}
                        /> */}
                        {/* <GlitchText text='School Programs' style={{
                            maxWidth: '95vw'
                        }} /> */}
                        <Text3D
                            text="School Programs"
                            color="white"
                            pixelOffset={-2}
                            shadowColors={shadowColors2}
                            fontSize={'50px'}
                        />
                        <p className='description'>
                            Empower your students with the cutting edge of educational technology through <span className="s3">Clash of Code</span>.
                        </p>
                        <p className='description'>
                            We provide a range of <span className="s2">in-person</span> and <span className="s2">online</span> after school programs to get students from zero to hero in Python, getting them battle ready! Allowing them to freely compete amongst each other in an organised <span className="s2">tournament</span>.
                        </p>
                        <p className='description'>See our curriculum & benefits!</p>
                        <GlowingButton onClick={() => {
                            navigate('/offering-programs');
                        }} text={"SEE MORE"} />
                    </div>
                </div>
                <div className='half-flex' style={{ marginTop: '70px' }} id="half-flex-1">
                    <div>
                        {/* <GlitchText text='Tutorials' style={{}} /> */}
                        <Text3D
                            text="Tutorials"
                            color="white"
                            pixelOffset={-2}
                            shadowColors={shadowColors2}
                            fontSize={'50px'}
                        />
                        <p className='description'>
                            We believe <span>self-learning</span> is the #1 way for students to get engaged and stay engaged in learning
                        </p>
                        <p className='description'>
                            We provide a wide range of free <span className="s3">video tutorials</span> and <span className="s2">documentation</span> for the game for students to be able to progress in their own time and pace
                        </p>
                        <GlowingButton color={'#BDFF00'} text={"LEARN MORE"} onClick={() => {
                            navigate('/tutorials')
                        }} />
                    </div>
                    <div id="tutorial-image" className='image-div'>

                    </div>
                </div>
                <div style={{ height: '50px' }} />
                <div id="blog-section">
                    {/* <GlitchText text='Showcase' style={{}} /> */}
                    <Text3D
                        text="Showcase"
                        color="white"
                        pixelOffset={-2}
                        shadowColors={shadowColors2}
                        fontSize={'50px'}
                    />
                    <p className='description'>
                        Take a look at our showcase to see pictures and video demos to see what <span>Clash of Code</span> is all about!
                    </p>
                    <GlowingButton color={'#FF007A'} text={"BLOW MY MIND"} onClick={() => {
                        navigate('/showcase')
                    }} />
                </div>
            </div>
            <div className='slanted-border'>
                <svg xmlns="http://www.w3.org/2000/svg" width="2512" height="72" viewBox="0 0 2512 72" fill="none">
                    <path d="M-35 68L2732 4" stroke="white" stroke-width="7" stroke-dasharray="14 14" />
                </svg>
            </div>
            <div id="contact-section" ref={contactRef}>
                {
                    !sentEmailOk ?
                        !loading ?
                            <>
                                <Text3D
                                    text="Book a Free Introductory Call"
                                    color="white"
                                    pixelOffset={2}
                                    shadowColors={shadowColors3}
                                    fontSize={'50px'}
                                />
                                <p className='description'>Book a free 10 minute call to discuss how we can incorporate Clash of Code into your school or for any general inquiries</p>
                                <form ref={form} onSubmit={sendEmail}>
                                    <input name="to_name" type="text" className="feedback-input" placeholder="Name / School Name" />
                                    <input name="to_email" type="email" className="feedback-input" placeholder="Email" />
                                    <textarea name="comment" className="feedback-input" placeholder="Comment"></textarea>
                                    <GlowingButton text={"SUBMIT"} color={'white'} />
                                </form>
                            </> : <>
                                <p className='description'>Sending...</p>
                            </>
                        : <>

                            <Text3D
                                text="INVITE SENT!"
                                color="white"
                                pixelOffset={2}
                                shadowColors={shadowColors3}
                            />

                            <p className='description'>Check your email and book in a time using the Calendly link, see you soon!</p>

                        </>
                }
            </div>
            <MiniGlitchText>Clash of Code</MiniGlitchText>
            <div className='footer'>
                <p>ajays.workemail@gmail.com</p>
                <p>+61 0402670252</p>
            </div>
            <div></div>
        </div>

    </div >

}

export default LandingPage;

