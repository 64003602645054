import "./Common.css";

export function SocialsBar(props: any) {

    return (
        <div className="socials-bar">
            <div className="logo-social" id="insta"></div>
            <div className="logo-social" id="discord"></div>
            <div className="logo-social" id="youtube"></div>
        </div >
    );

}