
import { useEffect, useState } from 'react';
import './MainComponents.css';
import { useNavigate } from 'react-router-dom';

export function Header(props: any) {

    const [hambugerOpen, setHambugerOpen] = useState(false);
    const [headerBackground, setHeaderBackground] = useState(false);

    const navigator = useNavigate();

    useEffect(() => {

        document.addEventListener("scroll", (event) => {
            var top = window.pageYOffset || document.documentElement.scrollTop;

            var limit = props.heightLimit ?? window.innerHeight;

            if (top > limit) {
                setHeaderBackground(true);
            }
            else {
                setHeaderBackground(false);
            }
        });

        document.addEventListener("resize", (event) => {
            var top = window.pageYOffset || document.documentElement.scrollTop;
            var limit = props.heightLimit ?? window.innerHeight;

            if (top > limit) {
                setHeaderBackground(true);
            }
            else {
                setHeaderBackground(false);
            }
        });


    }, [props.heightLimit]);


    return (
        <>
            <div className="header prevent-select" style={{
                backgroundColor: headerBackground ? "#11161ce2" : "transparent"
            }}>
                <div className='sub-header-section' style={{
                    justifyContent: 'flex-end'
                }}>
                    <p onClick={() => {
                        navigator('/tutorials');
                    }}>Tutorials</p>
                    <p onClick={() => {
                        navigator('/showcase');
                    }}>Showcase</p>
                </div>
                <div className='logo' onClick={() => {
                    navigator('/');
                }}></div>
                <div className='sub-header-section' style={{
                    justifyContent: 'flex-start'
                }}>
                    <p onClick={() => {
                        navigator('/offering-programs');
                    }}>Offering Programs</p>
                    <p onClick={() => {
                        props.onContactClick();
                    }}>Contact</p>
                </div>
            </div>
            <div className="ham-header">
                <div className='logo' onClick={() => {
                    navigator('/');
                }} />
                <div className='hamburger-open' onClick={() => {
                    console.log("F");
                    setHambugerOpen(true);
                    console.log(hambugerOpen);
                }} />
            </div>
            {hambugerOpen === true ?
                <div className="ham-open-header prevent-select">
                    <div className="ham-header" style={{ zIndex: 101, display: 'flex' }}>
                        <div className='logo' onClick={() => {
                            navigator('/');
                            setHambugerOpen(false);
                        }} />
                        <div className='hamburger-close' onClick={() => {
                            setHambugerOpen(false);
                        }} />
                    </div>
                    <div className='ham-open-list'>
                        <div onClick={() => {
                            navigator('/offering-programs');
                        }}>
                            Offering Programs
                        </div>
                        <div onClick={() => {
                            navigator('/tutorials');
                        }}>
                            Tutorials
                        </div>
                        <div onClick={() => {
                            navigator('/showcase');
                        }}>
                            Showcase
                        </div>
                        <div onClick={() => {
                            props.onContactClick();
                            setHambugerOpen(false);
                        }}>
                            Contact
                        </div>
                    </div>
                </div> : <div></div>}
        </>
    )


}