import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Heading } from "../../components/Heading";
import { CodeLayer } from "./CodeLayer";

export default function ComingSoonPage(props: any) {

    const navigate = useNavigate();

    return (
        <div className='backdrop'>
            <Header onContactClick={() => {
                navigate("/?moveTo=contact")
            }} />
            <CodeLayer />
            <center style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Heading>COMING SOON</Heading>
                <a href="/"><p className="description">Hold tight! Crash is still under development. Click to go back</p></a>
            </center>
        </div>
    )
}