import React from 'react';
import './App.css';
import LandingPage from './pages/LandingPage/LandingPage';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/LandingPage/ErrorPage';
import ProgramsPage from './pages/Other/Programs';
import ComingSoonPage from './pages/LandingPage/ComingSoonPage';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
      errorElement: <ErrorPage />
    } as any,
    {
      path: "/offering-programs",
      element: <ProgramsPage />,
      errorElement: <ErrorPage />
    },
    {
      path: "/tutorials",
      element: <ComingSoonPage />,
      errorElement: <ErrorPage />
    },
    {
      path: "/showcase",
      element: <ComingSoonPage />,
      errorElement: <ErrorPage />
    }
  ])

  return (
    <div className="App">
      {/* <LandingPage /> */}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
